// src/contexts/LoadingContext.js
import React, { createContext, useState, useRef } from 'react';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const requestCount = useRef(0); // Usar useRef para manejar múltiples solicitudes concurrentes

    // Mostrar el loader
    const showLoader = () => {
        requestCount.current += 1;
        setIsLoading(true); // Mostrar siempre el loader cuando se hace una nueva solicitud
    };

    // Ocultar el loader solo si todas las solicitudes han terminado
    const hideLoader = () => {
        requestCount.current -= 1;

        if (requestCount.current <= 0) {
            requestCount.current = 0; // Asegurarse de que nunca sea menor que 0
            setIsLoading(false); // Ocultar el loader solo cuando no haya solicitudes pendientes
        }
    };

    return (
        <LoadingContext.Provider value={{ isLoading, showLoader, hideLoader }}>
            {children}
        </LoadingContext.Provider>
    );
};
