import React, { useEffect, useState } from "react";
import apiClient from "../../../../api/apiClient";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";

function Home() {
    const [orderSummary, setOrderSummary] = useState({
        inProgress: 0,
        completed: 0,
        completedWithError: 0,
        erroneous: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await apiClient.get('/resume');
                const statusCount = {
                    inProgress: 0,
                    completed: 0,
                    completedWithError: 0,
                    erroneous: 0,
                };

                response.data.forEach(status => {
                    updateStatusCount(statusCount, status);
                });

                setOrderSummary(statusCount);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const updateStatusCount = (statusCount, status) => {

            if (status !== 'undefined' && status !== '') {
                const statusMap = {
                    "0": 'inProgress',
                    "1": 'completed',
                    "2": 'completedWithError',
                    "3": 'erroneous',
                };

                const statusKey = statusMap[status.status];
                if (statusKey) {
                    statusCount[statusKey] = status.total;
                }
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <p>Ocurrió un error: {error}</p>;
    }

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h3 className="widget-title">Bienvenido</h3>
                            <p>Este es un resumen rápido de sus órdenes:</p>
                            <div className="row d-flex justify-content-around">
                                <div className="col-2 p-2 m-1 rounded" style={{ backgroundColor: '#8aaac3' }}>
                                    <div className="widget-one d-flex flex-column align-items-center">
                                        <h5 className="widget-title text-white">En progreso</h5>
                                        <p className="text-white display-6">{orderSummary.inProgress}</p>
                                    </div>
                                </div>
                                <div className="col-2 p-2 m-1 rounded" style={{ backgroundColor: '#77b586' }}>
                                    <div className="widget-one d-flex flex-column align-items-center">
                                        <h5 className="widget-title text-white">Completadas</h5>
                                        <p className="text-white display-6">{orderSummary.completed}</p>
                                    </div>
                                </div>
                                <div className="col-2 p-2 m-1 rounded " style={{ backgroundColor: '#ffb94f' }}>
                                    <div className="widget-one d-flex flex-column align-items-center">
                                        <h5 className="widget-title text-white">Completadas con error</h5>
                                        <p className="text-white display-6">{orderSummary.completedWithError}</p>
                                    </div>
                                </div>
                                <div className="col-2 p-2 m-1 rounded" style={{ backgroundColor: '#e7515a' }}>
                                    <div className="widget-one d-flex flex-column align-items-center">
                                        <h5 className="widget-title text-white">Erróneas</h5>
                                        <p className="text-white display-6">{orderSummary.erroneous}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <h4>¿Está buscando enrolar dispositivos?</h4>
                                    <ul>
                                        <li>Para enrolar dispositivos adquiridos, ingrese a la opción <Link to={'/'} className="dropdown-toggle">
                                            "Sin enrolar"</Link> que se encuentra en el menú lateral izquierdo.
                                        </li>
                                        <li>Dentro de esa opción, busque la orden que desea enrolar y haga click sobre ella para comenzar el proceso de enrolamiento.</li>
                                    </ul>
                                    <h4>¿Necesita procesar una devolución de dispositivos enrolados?</h4>
                                    <ul>
                                        <li>Ingrese a la opción <Link to={'/'} className="dropdown-toggle">
                                            "Completadas"</Link> que se encuentra en el menú lateral izquierdo.
                                        </li>
                                        <li>Dentro de esa opción, busque la orden que le será retornada y haga click sobre ella para comenzar el proceso de retorno.</li>
                                    </ul>
                                    <h4>¿Necesita modificar o anular una orden de enrolamiento?</h4>
                                    <ul>
                                        <li>Por favor, contacte con su ejecutivo de cuentas de StarTech a través del canal de comunicación habitual.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
