import React, { createContext, useContext, useState } from "react";

// Crear el contexto
const HeaderContext = createContext();

// Custom hook para usar el contexto
export const useHeader = () => {
    return useContext(HeaderContext);
};

// Provider que envolverá la parte de la aplicación que necesita acceso al contexto
export const HeaderProvider = ({ children }) => {
    const [headerTitle, setHeaderTitle] = useState("");

    return (
        <HeaderContext.Provider value={{ headerTitle, setHeaderTitle }}>
            {children}
        </HeaderContext.Provider>
    );
};
