import axios from 'axios';

let logoutFunction = null;
let showLoaderFunction = null;
let hideLoaderFunction = null;



const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Configurar las funciones que se pasarán a los interceptores
export const configureApiClient = (showLoader, hideLoader, logout) => {
    showLoaderFunction = showLoader;
    hideLoaderFunction = hideLoader;
    logoutFunction = logout;
};

// Interceptor para las solicitudes (antes de enviar)
apiClient.interceptors.request.use(
    (config) => {
        if (showLoaderFunction) {
            showLoaderFunction(); // Mostrar el loader antes de la petición
        }

        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        if (hideLoaderFunction) {
            hideLoaderFunction(); // Ocultar el loader en caso de error
        }
        return Promise.reject(error);
    }
);

// Interceptor para las respuestas
apiClient.interceptors.response.use(
    (response) => {
        if (hideLoaderFunction) {
            hideLoaderFunction(); // Ocultar el loader después de la respuesta exitosa
        }
        return response;
    },
    (error) => {
        if (hideLoaderFunction) {
            hideLoaderFunction(); // Ocultar el loader en caso de error
        }

        const { response } = error;

        if (response && response.status === 401 && logoutFunction) {
            logoutFunction(); // Ejecutar logout si la respuesta es un 401
        }

        if(response && response.status === 403) {
            window.history.back(); // Redireccionar a la página anterior
        }

        return Promise.reject(error);
    }
);

export default apiClient;
