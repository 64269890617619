import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../components/Table/Table";
import { Form, Row, Col } from "react-bootstrap";
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { formatDate, formatDateForInput } from "../../../../utils/dates";
import { useAuth } from '../../../../contexts/AuthProvider';

function EditableInput({ name, value, onChange }) {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "4px",
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginRight: "10px" }}
            >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
            </svg>
            <input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: "18px",
                }}
            />
        </div>
    );
}

function OrderOverride() {
    const { isLoggedIn, userInfo } = useAuth();
    const { id } = useParams();
    const { setHeaderTitle } = useHeader();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        customer_name: "",
        apple_organization_id: "",
        date: "",
        reseller_invoice_number: "",
        delivery_date: "",
        delivery_number: "",
    });

    const [formErrors, setFormErrors] = useState({});
    const [selectedPrimaryDevices, setSelectedPrimaryDevices] = useState([]);
    const [selectedPendingDevices, setSelectedPendingDevices] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [devicesPendingEnrollment, setDevicesPendingEnrollment] = useState(null);

    useEffect(() => {
        if (userInfo.role.read_level !== '2' && !userInfo.role.exectue) {
            navigate(-1);
        }
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await apiClient.get(`/enrollment-full-detail/${id}`);
                if (!["1", "2", "3"].includes(response.data.status)) {
                    navigate(-1);
                    return;
                }
                setData(response.data);

                setFormData({
                    customer_name: response.data.customer_name || "",
                    apple_organization_id: response.data.apple_organization_id || "",
                    date: response.data.date ? formatDateForInput(response.data.date) : "",
                    reseller_invoice_number: response.data.reseller_invoice_number || "",
                    delivery_date: response.data.delivery_date ? formatDateForInput(response.data.delivery_date) : "",
                    delivery_number: response.data.delivery_number || "",
                });

                const pendingResponse = await apiClient.get(
                    "/find-sales-record-item-pending-enrolled",
                    {
                        params: {
                            reseller_id: response.data.company_id,
                        },
                    }
                );
                setDevicesPendingEnrollment(pendingResponse.data);
                setLoading(false);
            } catch (err) {
                setError("Error al obtener las órdenes");
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);


    // Este useEffect se asegura de que los datos estén cargados antes de precargar las filas seleccionadas
    useEffect(() => {
        if (data.devices && data.devices.length > 0) {
            setSelectedPrimaryDevices(data.devices); // Precargar todas las filas una vez que los datos estén disponibles
        }
    }, [data.devices]);

    useEffect(() => {
        setHeaderTitle("Enrolamientos");
    }, [setHeaderTitle]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const isFormValid = () => {
        let errors = {};
        const {
            customer_name,
            apple_organization_id,
            date,
            reseller_invoice_number,
            delivery_date,
            delivery_number,
        } = formData;

        if (!customer_name) errors.customer_name = "Este campo es obligatorio.";
        if (!apple_organization_id)
            errors.apple_organization_id = "Este campo es obligatorio.";
        if (!date) errors.date = "Este campo es obligatorio.";
        if (!reseller_invoice_number)
            errors.reseller_invoice_number = "Este campo es obligatorio.";
        if (!delivery_date) errors.delivery_date = "Este campo es obligatorio.";
        if (!delivery_number) errors.delivery_number = "Este campo es obligatorio.";

        if (delivery_date && date && new Date(delivery_date) < new Date(date)) {
            errors.delivery_date =
                "La fecha de envío debe ser igual o posterior a la fecha de venta.";
        }

        if (
            selectedPrimaryDevices.length === 0 &&
            selectedPendingDevices.length === 0
        ) {
            errors.selectedDevices = "Debe seleccionar al menos un dispositivo.";
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            Swal.fire({
                icon: "warning",
                title: "Formulario incompleto",
                text: "Por favor, corrija los errores en el formulario antes de continuar.",
            });
        }

        return Object.keys(errors).length === 0;
    };

    const getSelectedDevices = () => {
        const combinedDevices = [
            ...selectedPrimaryDevices,
            ...selectedPendingDevices,
        ];

        const selectedIds = combinedDevices.map((device) => device.id);
        return Array.from(new Set(selectedIds));
    };

    const handleSubmit = async () => {
        if (!isFormValid()) return;

        const combinedSelectedDevices = getSelectedDevices();

        const enrollmentData = {
            ...formData,
            ids_devices: combinedSelectedDevices,
        };

        try {
            await apiClient.post(`/override-enrollment-order/${id}`, enrollmentData);
            Swal.fire({
                icon: "success",
                title: "Modificación exitosa",
                text: "La orden de enrolamiento ha sido actualizada.",
            });
            navigate(`/enrollments/${id}`);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.error || "Error al enviar la solicitud",
            });
        }
    };

    const headers = [
        { label: "Enrolado", accessor: "enroled", searchable: false },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
    ];

    const headersToEnroll = [
        { label: "Venta Startech", accessor: "invoice_number", searchable: true },
        { label: "SKU", accessor: "sku", searchable: true },
        { label: "Artículo", accessor: "description", searchable: true },
        { label: "Serie", accessor: "serial", searchable: true },
    ];

    const fieldNames = {
        customer_name: "Razón social del cliente final",
        apple_organization_id: "Apple Organization ID del cliente final",
        date: "Fecha de la venta",
        reseller_invoice_number: "Número de comprobante de venta",
        delivery_date: "Fecha de envío",
        delivery_number: "Número de envío",
        selectedDevices: "Selección de dispositivos",
    };

    return (
        <div>
            <div className="row layout-top-spacing layout-spacing">
                <div className="col-lg-9 col-xl-9 col-xxl-9 col-12">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                                <h5 className="widget-title mb-0">Modificar enrolamiento (OV)</h5>
                                <h5 className="mb-0">Orden Nº {data.reseller_invoice_number}</h5>
                            </div>
                            <Col>
                                <Form className="mb-5">
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group as={Row} className="mb-2">
                                                <Col sm={10}>
                                                    <EditableInput
                                                        name="customer_name"
                                                        value={formData.customer_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </Col>
                                                {formErrors.customer_name && (
                                                    <small className="text-danger">{formErrors.customer_name}</small>
                                                )}
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2">
                                                <Form.Label column sm={4} className="d-flex align-items-center">
                                                    Apple Organization ID
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <EditableInput
                                                        name="apple_organization_id"
                                                        value={formData.apple_organization_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </Col>
                                                {formErrors.apple_organization_id && (
                                                    <small className="text-danger">{formErrors.apple_organization_id}</small>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group as={Row} className="mb-2 justify-content-end">
                                                <Form.Label column sm={4} className="d-flex align-items-center">
                                                    Fecha de la orden:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <Form.Control
                                                        type="date"
                                                        name="date"
                                                        value={formData.date}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.date && (
                                                        <small className="text-danger">{formErrors.date}</small>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-2 justify-content-end">
                                                <Form.Label column sm={4}>
                                                    Fecha de envío:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <Form.Control
                                                        type="date"
                                                        name="delivery_date"
                                                        value={formData.delivery_date}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.delivery_date && (
                                                        <small className="text-danger">{formErrors.delivery_date}</small>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-2 justify-content-end">
                                                <Form.Label column sm={4}>
                                                    Número de envío:
                                                </Form.Label>
                                                <Col sm={6}>
                                                    <EditableInput
                                                        name="delivery_number"
                                                        value={formData.delivery_number}
                                                        onChange={handleInputChange}
                                                    />
                                                    {formErrors.delivery_number && (
                                                        <small className="text-danger">{formErrors.delivery_number}</small>
                                                    )}
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>

                                {selectedPrimaryDevices && selectedPrimaryDevices.length > 0 && (
                                    <Table
                                        data={data.devices}
                                        headers={headers}
                                        enableCheckbox={true}
                                        onSelectedRowsChange={setSelectedPrimaryDevices}
                                        selectedRows={selectedPrimaryDevices} // Sincronizar filas seleccionadas
                                        initialSelectedRows={selectedPrimaryDevices} // Precargar las filas seleccionadas
                                        dataName={"dispositivos"}
                                    />
                                )}

                                {formErrors.selectedDevices && (
                                    <small className="text-danger">{formErrors.selectedDevices}</small>
                                )}

                                <hr />
                                <div className="row">
                                    <h5>Enrolar dispositivos adicionales en esta orden</h5>
                                </div>
                                <div className="row">
                                    <p>Seleccione los dispositivos no enrolados que desea enrolar en esta orden</p>
                                </div>
                                {devicesPendingEnrollment && (
                                    <>
                                        <Table
                                            data={devicesPendingEnrollment}
                                            headers={headersToEnroll}
                                            enableCheckbox={true}
                                            onSelectedRowsChange={setSelectedPendingDevices}
                                            selectedRows={selectedPendingDevices}
                                            dataName={"dispositivos"}
                                        />
                                        {formErrors.selectedDevices && (
                                            <small className="text-danger">{formErrors.selectedDevices}</small>
                                        )}
                                    </>
                                )}
                            </Col>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row layout-top-spacing">
                <div className="col-lg-9 col-xl-9 col-xxl-9 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Enviar solicitud de modificación (OV)</h5>
                            <p>
                                Verifique los datos ingresados y presione el botón "Solicitar modificación"
                                para comenzar el proceso de desenrolamiento.
                            </p>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Solicitar modificación
                            </button>

                            {Object.keys(formErrors).length > 0 && (
                                <div className="mt-4 alert alert-danger">
                                    <ul>
                                        {Object.entries(formErrors).map(([key, error]) => (
                                            <li key={key}>{`${fieldNames[key]} - ${error}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderOverride;
