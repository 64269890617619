import React from "react";
import { useHeader } from "../../contexts/HeaderContext";
import { useSidebar } from "../../contexts/SidebarContext"; // Importar el contexto del Sidebar

const Header = () => {
    const { headerTitle } = useHeader();
    const { toggleSidebar } = useSidebar(); // Obtener la función para alternar el sidebar



    return (
        <div className="header-container fixed-top">
            <header className="header navbar navbar-expand-sm">
                <a
                    href="#"
                    className="sidebarCollapse"
                    data-placement="bottom"
                    onClick={(e) => {
                        e.preventDefault();
                        toggleSidebar(); // Alternar la visibilidad del Sidebar
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>
                <ul className="navbar-item flex-row">
                    <li className="nav-item align-self-center page-heading">
                        <div className="page-header">
                            <div className="page-title">
                                <h3>{headerTitle}</h3>
                            </div>
                        </div>
                    </li>
                </ul>
            </header>
        </div>
    );
};

export default Header;
