import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import apiClient from "../../../../api/apiClient";
import Table from "../../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import CompanySelect from "../../../../components/Selects/CompanySelect/CompanySelect";
import RoleSelect from "../../../../components/Selects/RoleSelect/RoleSelect";
import Swal from 'sweetalert2';
import { useAuth } from '../../../../contexts/AuthProvider';


function ManageUsers() {
    const { userInfo } = useAuth();
    const { setHeaderTitle } = useHeader();
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        password: '',
        role_id: '',
        company_id: ''
    });
    const [selectedReadLevel, setSelectedReadLevel] = useState(null);
    const [formErrors, setFormErrors] = useState({}); // Estado para almacenar errores del formulario
    const [generalError, setGeneralError] = useState(''); // Para errores generales
    const navigate = useNavigate();

    // Obtener usuarios
    useEffect(() => {
        setHeaderTitle("Gestión de usuarios");
        const fetchData = async () => {
            try {
                const response = await apiClient.get("/users");
                setData(response.data);
            } catch (err) {
                setError("Error al obtener los usuarios");
            }
        };

        fetchData();
    }, [setHeaderTitle]);

    const headers = [
        { label: "Razón social", accessor: "company_name", searchable: "true" },
        { label: "Nombre", accessor: "full_name", searchable: "true" },
        { label: "Email", accessor: "email", searchable: "true" },
        { label: "Rol", accessor: "role_name", searchable: "true" },
    ];

    const handleRowClick = (row) => {
        navigate(`/users/${row.id}`);
    };

    const handleShowModal = () => {
        setShowModal(true);
        setFormErrors({});
        setGeneralError('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            full_name: '',
            email: '',
            password: '',
            role_id: '',
            company_id: ''
        });
        setSelectedReadLevel(null);
        setFormErrors({});
        setGeneralError('');
    };

    // Manejamos los cambios en el formulario para los inputs de texto
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Manejamos los cambios en los selects
    const handleSelectChange = (selectedOption, { name }) => {
        if (name === "company_id") {
            const selectedCompany = selectedOption ? selectedOption.value : '';
            const selectedReadLevel = selectedOption && selectedOption.apple_ship_to_id !== null ? 2 : 1;

            setFormData((prevData) => ({
                ...prevData,
                [name]: selectedCompany
            }));
            setSelectedReadLevel(selectedReadLevel);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: selectedOption ? selectedOption.value : ''
            }));
        }
    };

    // Manejamos el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors({}); // Limpia los errores anteriores
        setGeneralError(''); // Limpia el error general

        try {
            await apiClient.post("/users", formData);
            setShowModal(false);
            // Refresca la lista de usuarios después de crear uno nuevo
            const response = await apiClient.get("/users");
            setData(response.data);
            Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Usuario creado con éxito',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
        } catch (err) {
            if (err.response && err.response.data) {
                const errorData = err.response.data;

                // Si hay errores específicos de los campos
                if (errorData.errors) {
                    setFormErrors(errorData.errors);
                } else if (errorData.message) {
                    // Si hay un mensaje general de error
                    setGeneralError(errorData.message);
                } else {
                    setGeneralError("Algo salió mal. Por favor, intente de nuevo.");
                }
            } else {
                setGeneralError("Error de red. No se pudo conectar con el servidor.");
            }
        }
    };

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mt-2">Usuarios</h5>
                                        {userInfo.role.execute && (
                                            <Button className="btn btn-primary ml-auto" onClick={handleShowModal}>
                                                Nuevo usuario
                                            </Button>)}
                                    </div>
                                </div>
                            </div>
                            <Table
                                data={data}
                                headers={headers}
                                dataName={"usuarios"}
                                enableRowClick={userInfo.role.execute}
                                onRowClick={userInfo.role.execute ? handleRowClick : undefined}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal para crear usuario */}
            <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Crear nuevo usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {generalError && (
                        <small className="text-danger">
                            {generalError}
                        </small>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formCompany">
                            <Form.Label>Empresa</Form.Label>
                            <CompanySelect
                                value={formData.company_id}
                                onChange={handleSelectChange}
                            />
                            {formErrors.company_id && (
                                <small className="text-danger">
                                    {formErrors.company_id[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Form.Group controlId="formFullName">
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control
                                type="text"
                                name="full_name"
                                value={formData.full_name}
                                onChange={handleInputChange}
                                required
                            />
                            {formErrors.full_name && (
                                <small className="text-danger">
                                    {formErrors.full_name[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            {formErrors.email && (
                                <small className="text-danger">
                                    {formErrors.email[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Form.Group controlId="formRole">
                            <Form.Label>Rol</Form.Label>
                            <RoleSelect
                                name="role_id"
                                onChange={handleSelectChange}
                                readLevel={selectedReadLevel}
                            />
                            {formErrors.role_id && (
                                <small className="text-danger">
                                    {formErrors.role_id[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Button variant="primary mt-3" type="submit">
                            Crear usuario
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ManageUsers;
