import React, { useEffect, useState } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import "./AllOrders.css";
import Table from "../../../../components/Table/Table";
import apiClient from "../../../../api/apiClient";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/dates";
import { useAuth } from '../../../../contexts/AuthProvider';

function EnrollmentsCompletedWithError() {
  const navigate = useNavigate();
  const { setHeaderTitle } = useHeader();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userInfo } = useAuth();
  const { read_level } = userInfo.role || {};

  useEffect(() => {
    setHeaderTitle("Enrolamientos");

    const fetchData = async () => {
      try {
        const response = await apiClient.get("/list-enrollments-status", {
          params: {
            filter: { "enrollments.status": "2" },
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error al obtener las órdenes");
        setLoading(false);
      }
    };

    fetchData();
  }, [setHeaderTitle]);


  // Defining headers for the table
  const headers = [
    { label: "Fecha enrolamiento", accessor: "created_at", searchable: true },
    ...(read_level === '2'
      ? [
        {
          label: "Revendedor",
          accessor: "company_name",
          searchable: true,
        },
      ]
      : []),
    {
      label: "N° Factura del reseller",
      accessor: "reseller_invoice_number",
      searchable: true,
    },
    { label: "Cliente", accessor: "customer_name", searchable: true },
    {
      label: "Organization ID",
      accessor: "apple_organization_id",
      searchable: true,
    },
    {
      label: "Tipo",
      accessor: "transaction_type",
      searchable: true,
    },
    { label: "DEP Transaction ID", accessor: "dep_transaction_id", searchable: true },
    {
      label: "Error",
      accessor: "dep_transaction_message",
      searchable: true,
    },
  ];

  if (loading) {
    return <div>Cargando órdenes...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const formattedData = data.map((order) => ({
    ...order,
    created_at: formatDate(order.created_at),
    enrollment_status: "Completed",
    error_code: order.error_code || "",
    error_message: order.error_message || ""
  }));

  const handleRowClick = (row) => {
    navigate(`/enrollments/${row.id}`);
  };

  return (
    <div>
      <div className="row layout-top-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
          <div className="widget widget-content-area br-4">
            <div className="widget-one">
              <h5 className="widget-title">Enrolamientos completados con error</h5>
              <Table
                data={formattedData}
                headers={headers}
                enableRowClick={true}
                onRowClick={handleRowClick}
                dataName={"ordenes"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentsCompletedWithError;
