import React, { useState, useEffect, useCallback } from "react";
import { useHeader } from "../../../../contexts/HeaderContext";
import Swal from 'sweetalert2';
import apiClient from "../../../../api/apiClient";
import Table from "../../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../contexts/AuthProvider';


function ManageReseller() {
    const { setHeaderTitle } = useHeader();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { userInfo } = useAuth();

    useEffect(() => {
        setHeaderTitle("Gestión de revendedores");
    }, [setHeaderTitle]);

    // Definir `fetchData` para obtener la información
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await apiClient.get("/companies");
            setData(response.data.filter(company => company.erp_id !== null));
            setLoading(false);
        } catch (err) {
            setError("Error al obtener las órdenes");
            setLoading(false);
        }
    }, []);

    // Llamar a `fetchData` cuando el componente se monta
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const headers = [
        { accessor: "erp_id", label: "Identificador ERP", searchable: true },
        { accessor: "name", label: "Razón social", searchable: true },
        { accessor: "apple_reseller_id", label: "Apple Reseller ID", searchable: true }
    ];

    // Acción para eliminar revendedor
    const handleDelete = async (row, e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation(); // Detener la propagación solo si existe el evento
        }

        Swal.fire({
            title: `¿Eliminar ${row.name}?`,
            text: "Ya no podrá acceder a la plataforma de enrolamiento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            customClass: {
                confirmButton: 'disapprove-confirm-custom',
                cancelButton: 'disapprove-cancel-custom'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await apiClient.delete(`/companies/${row.id}`);
                    Swal.fire(
                        'Eliminado!',
                        `${row.name} ha sido eliminado.`,
                        'success'
                    );
                    // Actualizar la tabla después de eliminar
                    fetchData();
                } catch (error) {
                    Swal.fire(
                        '¡Error!',
                        `Hubo un problema al eliminar al revendedor ${row.name}.`,
                        'error'
                    );
                }
            }
        });
    };

    const handleRowClick = (row) => {
        navigate(`/resellers/${row.id}`);
    };

    const actions = [
        {
            label: "",
            className: "",
            onClick: handleDelete,
            render: (row) => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-trash-2"
                    onClick={(e) => handleDelete(row, e)} // Pasamos el evento 'e' aquí
                    style={{ cursor: "pointer" }} // Estilo para indicar que es clickeable
                >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
            )
        }
    ];

    if (loading) {
        return <div>Cargando órdenes...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
                    <div className="widget widget-content-area br-4">
                        <div className="widget-one">
                            <h5 className="widget-title">Gestión de revendedores</h5>
                            <Table
                                data={data}
                                headers={headers}
                                columnActionsName="Acciones"
                                dataName="revendedores"
                                enableRowClick={userInfo.role.execute}
                                onRowClick={handleRowClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageReseller;
